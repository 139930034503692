<template>
  <el-card class="card_content" shadow="never">
    <div slot="header">
      <span class="iconfont icon-SystemConfig">&nbsp;基础配置</span>
    </div>
    <el-table :data="list" size="mini">
      <el-table-column prop="type" label="模板名称" width="200"></el-table-column>
      <el-table-column prop="template" label="模板ID" width="300">
        <template slot-scope="scope">
          <el-input
            v-if="scope.row.isSelectedTemplate"
            size="mini"
            v-model="scope.row.template"
            @focus="focusTemplateEvent(scope.row)"
            @blur="blurTemplateEvent(scope.row)"
            v-focus
          ></el-input>
          <span v-else>
            {{ scope.row.template }}
            <i
              class="el-icon-edit-outline"
              style="color:#5e72f9;font-size:14px;margin-left:10px;cursor:pointer;vertical-align:middle"
              @click="cellTemplateClick(scope.row)"
            ></i>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="content" label="模板内容" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-input
            v-if="scope.row.isSelectedContent"
            size="mini"
            type="textarea"
            v-model="scope.row.content"
            @focus="focusContentEvent(scope.row)"
            @blur="blurContentEvent(scope.row)"
            v-focusTextArea
          ></el-input>
          <span v-else>
            {{ scope.row.content }}
            <i
              class="el-icon-edit-outline"
              style="color:#5e72f9;font-size:14px;margin-left:10px;cursor:pointer;vertical-align:middle"
              @click="cellContentClick(scope.row)"
            ></i>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="state" label="状态" width="100">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.state"
            active-value="on"
            inactive-value="off"
            @click="onChangeState(scope.row)"
          ></el-switch>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>
<script>
import { getSmsTemplateApi, editSmsTemplateApi } from "../../../api/data";
export default {
  data() {
    return {
      list: []
    };
  },
  //自定义指令对文本框添加焦点
  directives: {
    focus: {
      inserted: function(el) {
        el.querySelector("input").focus();
      }
    },
    focusTextArea: {
      inserted: function(el) {
        el.querySelector("textarea").focus();
      }
    }
  },
  created() {
    this.initLoad();
  },
  methods: {
    initLoad() {
      getSmsTemplateApi().then(res => {
        this.list = res.data;
        this.list = res.data.map(item => {
          return {
            ...item,
            isSelectedTemplate: false,
            isSelectedContent: false
          };
        });
      });
    },
    onChangeState(row) {
      editSmsTemplateApi(row).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.initLoad();
        }
      });
    },
    //编辑模板ID事件
    cellTemplateClick(row) {
      row.isSelectedTemplate = !row.isSelectedTemplate;
    },
    //触发焦点记录用户聚焦前的值
    focusTemplateEvent(row) {
      row.oldTemplate = row.template;
    },
    //失去焦点事件
    blurTemplateEvent(row) {
      row.isSelectedTemplate = !row.isSelectedTemplate;
      //判断前后两次的值是否一样
      if (row.template !== row.oldTemplate) {
        editSmsTemplateApi(row).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initLoad();
          }
        });
      }
    },

    //编辑模板内容事件
    cellContentClick(row) {
      row.isSelectedContent = !row.isSelectedContent;
    },
    //触发焦点记录用户聚焦前的值
    focusContentEvent(row) {
      row.oldContent = row.content;
    },
    //失去焦点事件
    blurContentEvent(row) {
      row.isSelectedContent = !row.isSelectedContent;
      //判断前后两次的值是否一样
      if (row.content !== row.oldContent) {
        editSmsTemplateApi(row).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initLoad();
          }
        });
      }
    }
  }
};
</script>
<style lang='scss'>
</style>